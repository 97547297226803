<template>
  <a-tabs v-model:activeKey="activeKey">
    <a-tab-pane key="1" tab="机构门店">
      <table-page :key="activeKey" :config="config" />
    </a-tab-pane>
    <a-tab-pane key="2" tab="权重设置">
      <a-card>
        <h3>门店信息</h3>
        <div style="display: flex; align-items: center; margin-top: 20px; margin-bottom: 10px;">
          <span style="margin-right: 8px;">服务项目</span>
          <span>每项</span>
          <a-input-number :min="0" :max="form.serviceProjectHigh" style="width: 50px; margin: 0 6px;"
            v-model:value.trim="form.serviceProjectPer" placeholder=""></a-input-number>
          <span>分，最高</span>
          <a-input-number :min="5" style="width: 50px; margin: 0 6px;" v-model:value.trim="form.serviceProjectHigh"
            placeholder=""></a-input-number>
          <span>分</span>
        </div>
        <div style="display: flex; align-items: center; margin-top: 20px; margin-bottom: 10px;">
          <span style="margin-right: 8px;">服务团队</span>
          <span>每项</span>
          <a-input-number :min="0" :max="form.serviceTeamHigh" style="width: 50px; margin: 0 6px;"
            v-model:value.trim="form.serviceTeamPer" placeholder=""></a-input-number>
          <span>分，最高</span>
          <a-input-number :min="5" style="width: 50px; margin: 0 6px;" v-model:value.trim="form.serviceTeamHigh"
            placeholder=""></a-input-number>
          <span>分</span>
        </div>
        <div style="display: flex; align-items: center; margin-top: 20px; margin-bottom: 10px;">
          <span style="margin-right: 8px;">荣誉资质</span>
          <span>每项</span>
          <a-input-number :min="0" :max="form.honorQualificationHigh" style="width: 50px; margin: 0 6px;"
            v-model:value.trim="form.honorQualificationPer" placeholder=""></a-input-number>
          <span>分，最高</span>
          <a-input-number :min="5" style="width: 50px; margin: 0 6px;" v-model:value.trim="form.honorQualificationHigh"
            placeholder=""></a-input-number>
          <span>分</span>
        </div>
        <div style="display: flex; align-items: center; margin-top: 20px;">
          <span style="margin-right: 8px;">录取案例</span>
          <span>每项</span>
          <a-input-number :min="0" :max="form.caseHigh" style="width: 50px; margin: 0 6px;" v-model:value.trim="form.casePer"
            placeholder=""></a-input-number>
          <span>分，最高</span>
          <a-input-number :min="5" style="width: 50px; margin: 0 6px;" v-model:value.trim="form.caseHigh"
            placeholder=""></a-input-number>
          <span>分</span>
        </div>
        <div style="display: flex; align-items: center; margin-top: 20px; margin-bottom: 40px;">
          <span style="margin-right: 8px;">攻略资讯</span>
          <span>每项</span>
          <a-input-number :min="0" :max="form.strategyConsultationHigh" style="width: 50px; margin: 0 6px;" v-model:value.trim="form.strategyConsultationPer"
            placeholder=""></a-input-number>
          <span>分，最高</span>
          <a-input-number :min="5" style="width: 50px; margin: 0 6px;" v-model:value.trim="form.strategyConsultationHigh"
            placeholder=""></a-input-number>
          <span>分</span>
        </div>
        <p style="color: #999; padding: 20px 0;">每项评分必填，最高分选填(最小填写5分)，最高分为空时代表不设最高分限制。</p>
        <a-button type="primary" @click="submit" :loading="submitLoading">保存</a-button>
      </a-card>
    </a-tab-pane>
  </a-tabs>
</template>

<script>
import TablePage from "@/components/TablePage";
import { get } from "@/utils/http";
export default {
  name: 'SaasAdminRebateRulesReviewDetail',
  components: { TablePage },
  props: ['selectedRows'],
  data() {
    return {
      activeKey: '1',
      config: {
        dataUrl: `/sortrule/operation/list`,
        filter: {
          controls: [
            {
              key: 'orgName',
              label: '机构名',
              type: 'text'
            },
          ]
        },
        columns: [
          {
            key: 'orgName',
            title: '机构名',
            width: '130px',
          },
          {
            key: 'caseNumber',
            title: '门店信息',
            width: '130px',
            type: 'modal',
            config: {
              text: (row) => {
                return row.totalScore
              },
              title: '门店信息明细',
              content: (row) => {
                let html = `<div style="margin-bottom: 8px;text-align: center;">服务项目：${row.serviceProject}</div>
                            <div style="margin-bottom: 8px;text-align: center;">服务团队：${row.serviceTeam}</div>
                            <div style="margin-bottom: 8px;text-align: center;">荣誉资质：${row.honorQualification}</div>
                            <div style="margin-bottom: 8px;text-align: center;">录取案例：${row.caseNumber}</div>
                            <div style="margin-bottom: 8px;text-align: center;">攻略资讯：${row.strategyConsultation}</div>
                            <div style="text-align: center;">合计：${(parseFloat(row.serviceProject) + parseFloat(row.serviceTeam) + parseFloat(row.honorQualification) + parseFloat(row.caseNumber) + parseFloat(row.strategyConsultation)).toFixed(2)}</div>`
                return html
              }
            }
          },
          {
            key: 'number',
            title: '运营系数',
            width: '130px',
          },
          {
            key: 'totalScore',
            title: '总分',
            width: '130px',
          },
          {
            key: 'name',
            title: '操作',
            type: 'actions',
            fixed: 'right',
            width: 60
          }
        ],
        /* 表格操作 */
        actions: [
        ],
        /* 表格排序 */
        sorter: {
          /* 可排序列 */
          sortableColumns: [''],
          /* 默认排序 */
          defaultSort: ''
        },
        rowActions: [
          {
            key: 'detail',
            text: '编辑运营系数',
            type: 'blank',
            permission: '/sortrule/operation/edit',
            config: {
              title: '编辑运营系数',
              component: () => import("./editOperate")
            }
          }
        ]
      },
      form: {},
      submitLoading: false
    };
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    async getDetail() {
      try {
        let res = await get("/sortrule/weight/get")
        if (res.code == '200') {
          this.form = res.data;
        }
      } catch (e) {
        this.$message.error(e.message)
      }
    },

    async submit() {
      if (!this.form.serviceProjectPer
        || !this.form.serviceTeamPer
        || !this.form.honorQualificationPer
        || !this.form.casePer
        || !this.form.strategyConsultationPer
      ) {
        this.$message.error('请输入每项分数');
        return
      }
      try {
        await this.$http.post('/sortrule/weight/edit', {
          id: this.form.id,
          serviceProjectPer: this.form.serviceProjectPer,
          serviceProjectHigh: this.form.serviceProjectHigh,
          serviceTeamPer: this.form.serviceTeamPer,
          serviceTeamHigh: this.form.serviceTeamHigh,
          honorQualificationPer: this.form.honorQualificationPer,
          honorQualificationHigh: this.form.honorQualificationHigh,
          casePer: this.form.casePer,
          caseHigh: this.form.caseHigh,
          strategyConsultationPer: this.form.strategyConsultationPer,
          strategyConsultationHigh: this.form.strategyConsultationHigh,
        });
        this.$message.success('设置成功');
        this.getDetail();
      } catch ({ message }) {
        this.$message.error(message);
      }
      this.submitLoading = false;
    },
  }

};
</script>

<style lang="less" scoped></style>